import React from 'react'
import styles from './home.module.scss'

function Home(props) {
  const cardsArray = [
    {
      title: '实时数据采集',
      icon: require('./img/case1.png').default,
      content: '适配主流消息队列实现日志数据的实时采集，通过数据库 redo 回放、CDC、binlog 回放技术实现数据库数据实时采集，使用库仓一体方案构造无感知的数据接入，与后续的实时/批计算无缝衔接。'
    }, {
      title: '实时任务开发',
      icon: require('./img/case2.png').default,
      content: '强大的 IDE 套件，支持 jar、SQL、画布三种开发模式，支持拖拽、模块化、多语言等特性，提供在线调试、版本管理等功能，利用统一元数据中心与离线共享数仓模型，消除二义性。'
    }, {
      title: '统一元数据',
      icon: require('./img/case3.png').default,
      content: '与离线数仓共享元数据中心，使用统一数据模型抽象流表和批表，实现元数据流表批表为一体和任务间模型复用，用户无需构造 DDL，专注于核心业务流程，极大提升效能，基于统一元数据提供了全链路血缘。'
    }, {
      title: '全链路监控',
      icon: require('./img/case4.png').default,
      content: '提供任务的全生命周期事件管理，支持任务运行日志收集与检索，支持任务运行态各种维度 metrics 的汇总与展示，基于全链路监控与智能诊断，实现问题快速排查和恢复，保障 SLA。'
    },
  ]
  const IDCArray = [
    {
      icon: require('./img/IDC1.png').default,
      text: '全方位，立体安全防御'
    },
    {
      icon: require('./img/IDC2.png').default,
      text: '一体化安全解决方案'
    },
    {
      icon: require('./img/IDC3.png').default,
      text: '充分考虑用户的需求'
    },
    {
      icon: require('./img/IDC4.png').default,
      text: '功能强大的内容安全服务'
    },
  ]
  const scenarioArray = [
    {
      icon: require('./img/scenario1.png').default,
      title: '数据仓库构建',
      content: '覆盖数据抽取、转换、加载、建模、分析、报表呈现、数据治理等数仓建设环节，能够进行海量数据存储计算和数据资产管理，提高数据的质量和一致性。'
    },
    {
      icon: require('./img/scenario2.png').default,
      title: '用户画像',
      content: '通过多源数据整合，结合业务场景需求构建完善的用户标签体系，用360度全景画像，还原用户的真实面貌；支持多标签、人群包等多种类型数据导出，对接各类业务系统，全方位赋能业务发展。'
    },
    {
      icon: require('./img/scenario3.png').default,
      title: '精准推荐',
      content: '以用户为中心，进行面向业务的指标拆解，提供集活动创建、执行、管理、反馈、迭代为一体的自动化运营平台，深度分析用户行为、属性、标签等数据，实现目标人群的精准触达，赋能数字化建设，提升转化。'
    },
  ]
  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
      }
    }
  }
  return (
    <div className={styles.home}>
      <div id={'home'} className={styles.header}>
        <div className={styles.nav}>
          <img src={require('./img/logo.png').default} alt=""/>
          <ul>
            <li className={styles.activated} onClick={() => scrollToAnchor('home')}>
              首页
            </li>
            <li onClick={() => scrollToAnchor('case')}>
              方案特色
            </li>
            <li onClick={() => scrollToAnchor('storage')}>
              存储与大数据解决方案
            </li>
            <li onClick={() => scrollToAnchor('IDC')}>
              IDC数据中心解决方案
            </li>
            <li onClick={() => scrollToAnchor('scenario')}>
              应用场景
            </li>
            <li onClick={() => scrollToAnchor('about')}>
              关于我们
            </li>
          </ul>
        </div>
        <img src={require('./img/arrow_white.png').default} alt="" className={styles.arrow}
             onClick={() => scrollToAnchor('case')}/>
      </div>
      <div id={'case'} className={styles.case}>
        <h2>方案特色</h2>
        <p>实时数据采集，实时任务研发，统一元数据，全链路监控，完美解决所有需求。</p>
        <div className={styles.cardsContainer}>
          {cardsArray.map((item, index) =>
            <div className={styles.card} key={index}>
              <div className={styles.cardHeader}>
                <h3>{item.title}</h3>
                <img src={item.icon} alt=""/>
              </div>
              <div className={styles.caseDivider}/>
              <p>{item.content}</p>
            </div>
          )}
        </div>
      </div>
      <div id={'storage'} className={styles.storage}>
        <div className={styles.storageContainer}>
          <div className={styles.text}>
            <span>专业的更放心、更可靠</span>
            <h2>存储与大数据解决方案
              <div className={styles.storageDivider}/>
            </h2>
            <p>利用多种轻型数据库来接收发自客户端的数据，并且用户可以通过这些数据库来进行简单的查询和处理工作。
              将海量的来自前端的数据快速导入到一个集中的大型分布式数据库或者分布式存储集群，利用分布式技术来对存储于其内的集中的海量数据进行普通的查询和分类汇总等，以此满足大多数常见的分析需求。
              基于前面的查询数据进行数据挖掘，来满足高级别的数据分析需求。</p>
          </div>
          <img src={require('./img/storage.png').default} alt="" className={styles.pic}/>
        </div>
        <img src={require('./img/arrow_blue.png').default} alt="" className={styles.arrow1}
             onClick={() => scrollToAnchor('IDC')}/>
      </div>
      <div id={'IDC'} className={styles.IDC}>
        <h2>IDC数据中心解决方案</h2>
        <div className={styles.IDCPartsContainer}>
          {IDCArray.map((item, index) =>
            <div className={styles.IDCPart} key={index}>
              <img src={item.icon} alt=""/>
              <p>{item.text}</p>
            </div>
          )}
        </div>
        <div id={'scenario'} className={styles.scenario}>
          <h2>应用场景</h2>
          <div className={styles.scenarioPartsContainer}>
            {scenarioArray.map((item, index) =>
              <div className={styles.scenarioPart} key={index}>
                <div className={styles.scenarioPartHeader}>
                  <img src={item.icon} alt=""/>
                  <h3>{item.title}</h3>
                </div>
                <div className={styles.scenarioDivider}/>
                <p>{item.content}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div id={'about'} className={styles.about}>
        <div className={styles.aboutContainer}>
          <h2>关于我们</h2>
          <p>四川荣丰云大数据科技有限公司简称荣丰云，于2021年正式创立，有四川易家众联集团有限公司的技术入股，为用户提供了涵盖大数据基础设施和大数据应用在内的丰富产品及服务；公司致力于为全球用户提供高性价比的超算服务，目前在全国各地部署有大型超算中心及专业的运维团队和技术团队，基于自有的超算设备提供行业、产业领域产品的托管及产品流通等服务。</p>
          <p>易家众联着力于互联网10年，是一家集物联网智能构建、数据采集、数据融合、智能分析为一体的物联网大数据服务企业，在物联网OID、大数据、云计算、网络安全、软件开发等领域有深厚的技术储备，拥有业内高端的技术人才团队和丰富的行业经验；易家众联作为荣丰云的技术入股，使用最新技术作为支撑，为荣丰云用户提供大数据、云计算等综合性服务解决方案。</p>
          <p>公司秉承合规发展的原则，恪守国家法律法规、政策和集团各项内控制度，以扎实的技术底层为先导，整合多方优质资源，致力于为企业、政府、事业单供提供基础软件平台+应用+服务在内的专业一体化大数据解决方案，实现企业生产力和政府治理能力的数字化转型，让数据驱动进步。</p>
          <div className={styles.pics}>
            <div className={styles.left}>
              <img src={require('./img/about1.png').default} alt="" className={styles.pic1}/>
              <img src={require('./img/about2.png').default} alt="" className={styles.pic2}/>
            </div>
            <img src={require('./img/permission.png').default} alt="" className={styles.pic3}/>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <a className={styles.leftCss}
           href={'http://beian.miit.gov.cn/'}>蜀ICP备2021012986号</a>
        <span>服务咨询邮箱：Servicesupport@rongfengyun.pro</span>
        <a className={styles.link}
           href={'https://dxzhgl.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=&type=xuke'}>互联网数据中心业务 IDC编号
          B1-20213689</a>
      </div>
    </div>
  )
}

export default Home
