import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Index from '../pages'
function MainRouter() {
 return (
  <BrowserRouter>
   <Switch>
    <Route path="/" component={Index} />
   </Switch>
  </BrowserRouter>
 )
}

export default MainRouter
