import React from 'react'
import Home from './home/home'
import { Redirect, Route, Switch } from 'react-router-dom'
function Index() {
 return (
  <div>
   <Switch>
    <Redirect from="/" to="/home" exact />
    <Route path="/home" component={Home} exact />
   </Switch>
  </div>
 )
}

export default Index
